import React, { useState } from 'react';
import "../App.css";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";

import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Link , useNavigate} from 'react-router-dom';

import * as AiIcons from 'react-icons/ai';
import { IconContext } from 'react-icons/lib';
import { Nav, Navbar, Container } from "react-bootstrap";
import logo from '../calonlogotrnsp.png';
import AllHspDashboard from "./DashboardofAllHospitals";
import Dashboard from "./Dashboard";
import "@splidejs/splide/dist/css/splide.min.css";
import { Notifications } from 'react-push-notification';
import 'reactjs-popup/dist/index.css';

const NavIcon = styled(Link)`padding-left:2rem;font-size:2rem;display:flex;justify-content:flex-start;align-items:center;`;

const SidebarNav = styled.nav`background:#e4c4d1;width:280px;height:100vh;display:flex;justify-content:center;position:fixed;top:0;bottom:0;overflow:scroll;left:${({ sidebar }) => (sidebar ? '0' : '-100%')};transition:350ms;z-index:10;`;

const SidebarWrap = styled.div`width:100%;`;

// HomePage Component
const HomePage = () => {

  const navigate = useNavigate();


  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  var homestyle;

  if (sidebar === false) {
    homestyle = {
      display: "grid",
      gridTemplateColumns: "1fr",
    };
  } else {
    homestyle = {
      display: "grid",
      gridTemplateColumns: "280px 1fr",
    };
  }


  const refresh=()=>{
    navigate("/homepage/all_hospitals_dashboard");
    window.location.reload();
  }

  return (
    <div className="home" style={homestyle}>
      <Notifications style={{ width: "100%", position: 'absolute', top: 20, right: 20 }} />
      <div>
        <>
          <IconContext.Provider value={{ colour: "" }} >
            <SidebarNav sidebar={sidebar} style={{ color: "white" }}>
              <SidebarWrap >
                <NavIcon to='#' style={{ background: "#CBE4FF", height: "80px", width: "100%" }}>
                  <AiIcons.AiOutlineClose onClick={showSidebar} style={{ height: "30px", width: "30px" }} />
                  <img src={logo} style={{ height: "40px", width: "100px", marginLeft: "50px" }} alt="logo" />
                </NavIcon>
               
              </SidebarWrap>
            </SidebarNav>
          </IconContext.Provider>
        </>
      </div>
      <div className="full_frame_center" style={{ background: "#edf5fe" }}>
        <div style={{ background: "#CBE4FF", height: "80px", flexDirection: "column" }}>

          <div style={{ color: "black", height: "15px", width: "100%", fontSize: "12px", flexDirection: "row", textAlign: "end", paddingRight: "30px" }}>
            <b style={{ paddingRight: "20px", fontSize: "10px" }}>
            </b>(V:1.0)
          </div>

          <Nav style={{ height: "65px", width: "100%", flexDirection: "column", textAlign: "center", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
            {/* <NavIcon to='#' aria-label="Menu" style={{ height: "65px", width: "5%" }}>
              <FaIcons.FaBars onClick={showSidebar} />
            </NavIcon> */}
            <Navbar style={{ height: "65px", width: "95%" }}>
              <Container style={{ width: "100%" }}>
                <Navbar.Brand>
                  <label htmlFor="username" style={{ fontWeight: "bold", color: "#0060de", fontSize: "20px" }}>
                    <i style={{ color: "black", fontSize: "20px", marginLeft: "5px" }}>INFANT PROTECTION SYSTEM</i>
                  </label>
                </Navbar.Brand>
                <Navbar.Brand >
                  <label htmlFor="username" style={{ fontWeight: "bold", color: "green", fontSize: "20px" }}>CAL-ON INSTRUMENTS</label>
                  <img src={logo} style={{ height: "50px", width: "105px", marginLeft: "10px" }} alt="logo" />
                </Navbar.Brand>
              </Container>
            </Navbar>
          </Nav>
        </div>

        <div  style={{background:"#ffffff",height:"50px",flexDirection:"column"}}>
                  <Nav>
                    <div className='nav' style={{height:"50px",background:"#e4c4d1",width:"100%",display: "flex",flexDirection: "row",textAlign: "start",justifyContent: "start",alignItems: "center",paddingLeft:"50px"}}> 
                      <NavLink to="/homepage/all_hospitals_dashboard" onClick={refresh} className={({ isActive }) => (isActive ? 'active' : 'inactive')} >Home</NavLink>
                      <NavLink to="/homepage/dashboard" className={({ isActive }) => (isActive ? 'active' : 'inactive')} style={{marginLeft:"50px"}}>Active Dashboard</NavLink>
                    </div>
                  </Nav>
                </div>

        <div>
          <Routes >
            <Route exact path="/*" element={<AllHspDashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/all_hospitals_dashboard" element={<AllHspDashboard />} />
          </Routes >
        </div>
      </div>
    </div>
  );
};
export default HomePage;

