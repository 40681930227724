import React, { useState, useEffect } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";//npm install --save @fortawesome/react-fontawesome @fortawesome/fontawesome-svg-core @fortawesome/free-brands-svg-icons
import { faYoutube, faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { ImageList } from "@material-ui/core";
import Services from './Services'
import { Table } from "react-bootstrap";

import babyboy_greenround2 from '../babyboy_greenround2.png';
import babyboy_orangeround from '../babyboy_orangeround.png';
import babyboy_redround from '../babyboy_redround.png';

import babygirl_greenround2 from '../babygirl_greenround2.png';
import babygirl_orangeround from '../babygirl_orangeround.png';
import babygirl_redround from '../babygirl_redround.png';

import parent_green from '../parent_green.png';
import parent_orange from '../parent_orange.png';
import parent_red from '../parent_red.png';

import Popup from 'reactjs-popup';
import Form from 'react-bootstrap/Form'

const moment = require("moment");

const social_style = { background: "#edf5fe", color: "eb3223", padding: "5px", marginLeft: "5px", marginRight: "5px" }

// Dashboard Component
const Dashboard = () => {

    const [sorteddatevalue, setsorteddatevalue] = useState("");


    const [totalTcp, setTotalTcp] = useState([]);
    const [activeTcp, setActiveTcp] = useState([]);
    const [strapRemoveTcp, setStrapRemoveTcp] = useState([]);
    const [outGateTcp, setOutGateTcp] = useState([]);

    const [masterdata, setmasterdata] = useState({
        hospital_type: "",
        hospital_code: ""
    });


    const [tagSearchedData, setTagSearchedData] = new useState([]);

    const tagSearchedfunction = (event) => {

        if (event.target.value !== "") {

            if (isNaN(event.target.value)) {
                let firstLetter = event.target.value.charAt(0);
                event.target.value = firstLetter.toUpperCase() + event.target.value.slice(1);
                let value = event.target.value.toUpperCase();
                let result = [];
                result = totalTcp.filter((data) => {
                    let fildata = data.tag_patient_name;
                    return fildata.toUpperCase().search(value) !== -1;
                });
                const filterdata = [];
                for (let p = 0; p < totalTcp.length; p++) {
                    if (result[0].tag_patient_name === totalTcp[p].tag_patient_name) {
                        filterdata.push(totalTcp[p]);
                    }
                    if (totalTcp.length === p + 1) {
                        setTagSearchedData(filterdata);
                    }
                }
            } else {

                let value = event.target.value;
                let result = [];
                result = totalTcp.filter((data) => {
                    let fildata = data.tag_tagid;
                    return fildata.search(value) !== -1;
                });
                const filterdata = [];
                for (let p = 0; p < totalTcp.length; p++) {
                    if (result[0].tag_tagid === totalTcp[p].tag_tagid) {
                        filterdata.push(totalTcp[p]);
                    }
                    if (totalTcp.length === p + 1) {
                        setTagSearchedData(filterdata);
                    }
                }
            }
        } else {
            setTagSearchedData([]);
        }
    };

    function handleChangeHospitaltypes(event, frm) {
        
        if (frm === "hspttype") {
            masterdata.hospital_code = "";
        }
        const { name, value } = event.target;
        setmasterdata({ ...masterdata, [name]: value });
    }

    const [hospitaltype, sethospitaltype] = useState([]);
    const [hospitalcode, sethospitalcode] = useState([]);

    const [totalfinaltotalTcpList1, settotalfinaltotalTcpList1] = useState([]);

    useEffect(() => {
        try {
            const hospitaltype = [{ hospitaltype_name: "DME" }, { hospitaltype_name: "VVP" }];
            sethospitaltype(hospitaltype);

            const hospitalcode = [
                {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GMH-TPT"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "MCH-VJA"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "RIMS-ONG"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "RIMS-SKLM"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "RIMS-KDP"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GVH-VSKP"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "KGH-VSKP"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GGH-GTR"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GGH-KKD"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GGH-ATP"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "MCHSPSR-NLR"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GGH-KNL"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "DH-NDL"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "DH-MTM"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "DH-RJY"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "MCH-ELR"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "DH-HPUR"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "DH-TNL"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "DH-PDTR"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "DH-AKP"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "MCH-ONG"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "MCH-VZG"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "MCH-ADONI"
                }
            ];

            const filtered = hospitalcode.filter(hospitalcode => {
                return hospitalcode.hospitaltype_name === masterdata.hospital_type;
            });

            sethospitalcode(filtered);

        } catch (e) {
            console.log(e);
        }
    }, [masterdata.hospital_type]);


    const getData = () => {

        if (validateForm()) {

            const formData = new FormData()
            formData.append('Hospital_type', masterdata.hospital_type);
            formData.append('Hospital_code', masterdata.hospital_code);

            Services.getHospitalsdataByHospitalwise(formData).then((res) => {

                if (res.status === 200) {

                    if (res.data.success === true) {

                        const resdata = res.data.hospitaldata;

                        setTotalTcp([]);
                        setActiveTcp([]);
                        setStrapRemoveTcp([]);
                        setOutGateTcp([]);

                        const totalTcpList = [];
                        const activeTcpList = [];
                        const strapremoveTcpList = [];
                        const outgateTcpList = [];

                        settotalfinaltotalTcpList1([]);

                        for (let i = 0; i < resdata.length; i++) {

                            if (resdata[i].hospital_type === masterdata.hospital_type
                                && resdata[i].hospital_code === masterdata.hospital_code
                                && resdata[i].patient_status === "IN HOSPITAL") {

                                totalTcpList.push(resdata[i]);

                                if (resdata[i].tag_alertType_id === "33") {
                                    strapremoveTcpList.push(resdata[i]);
                                } else if (resdata[i].tag_alertType_id === "50") {
                                    outgateTcpList.push(resdata[i]);
                                } else {
                                    activeTcpList.push(resdata[i]);
                                }

                            }
                            if (resdata.length === i + 1) {

                                if (totalTcpList.length !== 0) {

                                    totalTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                    activeTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                    strapremoveTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                    outgateTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);


                                    setTotalTcp(totalTcpList);
                                    setActiveTcp(activeTcpList);
                                    setStrapRemoveTcp(strapremoveTcpList);
                                    setOutGateTcp(outgateTcpList);

                                    resdata.sort((firstItem, secondItem) => firstItem.tagId - secondItem.tagId);

                                    const dates = [];
                                    for (let h = 0; h < resdata.length; h++) {
                                        const [date, time] = resdata[h].tag_last_inserted_time.split(' ');
                                        if (isValidDate(date, "DD-MM-YYYY")) {
                                            const [d3, m3, y3] = date.split('-');
                                            const date1 = `${y3}/${m3}/${d3}`;
                                            const date2 = date1 + " " + time;
                                            const dit = {
                                                date: date2,
                                            }
                                            dates.push(dit);
                                        }
                                        if (resdata.length - 1 === h) {
                                            dates.sort((a, b) => new Date(a.date) - new Date(b.date))
                                            //const minDate = dates[0].date;
                                            const maxDate = dates.reverse()[0].date;
                                            const [fdate, ftime] = maxDate.split(' ');
                                            if (isValidDate(fdate, "YYYY/MM/DD")) {
                                                const [y3, m3, d3] = fdate.split('/');
                                                const date3 = `${d3}-${m3}-${y3}`;
                                                const date4 = date3 + " " + ftime;
                                                setsorteddatevalue(date4);
                                            }
                                        }
                                    }


                                } else {
                                    alert("Empty Data");
                                    setTotalTcp([]);
                                    setActiveTcp([]);
                                    setStrapRemoveTcp([]);
                                    setOutGateTcp([]);
                                    setsorteddatevalue("");

                                }
                            }
                        }


                        const finaltotalTcpList = [];

                        for (let k = 0; k < hospitalcode.length; k++) {

                            const totalTcpList = [];
                            const dischargedTcpList = [];
                            const inhospitalTcpList = [];
                            const activeTcpList = [];
                            const strapremoveTcpList = [];
                            const outgateTcpList = [];

                            let updateddatetime = "-";

                            for (let m = 0; m < resdata.length; m++) {

                                if (resdata[m].hospital_type === hospitalcode[k].hospitaltype_name
                                    && resdata[m].hospital_code === hospitalcode[k].hospitalcode_name) {
                                    totalTcpList.push(resdata[m]);

                                    if (resdata[m].patient_status === "IN HOSPITAL") {

                                        inhospitalTcpList.push(resdata[m]);
                                        if (resdata[m].tag_alertType_id === "33") {
                                            strapremoveTcpList.push(resdata[m]);
                                        } else if (resdata[m].tag_alertType_id === "50") {
                                            outgateTcpList.push(resdata[m]);
                                        } else {
                                            activeTcpList.push(resdata[m]);
                                        }

                                    } else if (resdata[m].patient_status === "DISCHARGE") {
                                        dischargedTcpList.push(resdata[m]);
                                    }

                                }


                                if (resdata.length === m + 1) {

                                    if (totalTcpList.length !== 0) {

                                        totalTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                        dischargedTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                        inhospitalTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                        activeTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                        strapremoveTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                        outgateTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);

                                        resdata.sort((firstItem, secondItem) => firstItem.tagId - secondItem.tagId);
                                        const dates = [];
                                        for (let h = 0; h < totalTcpList.length; h++) {
                                            const [date, time] = totalTcpList[h].tag_last_inserted_time.split(' ');
                                            if (isValidDate(date, "DD-MM-YYYY")) {
                                                const [d3, m3, y3] = date.split('-');
                                                const date1 = `${y3}/${m3}/${d3}`;
                                                const date2 = date1 + " " + time;
                                                const dit = {
                                                    date: date2,
                                                }
                                                dates.push(dit);
                                            }
                                            if (totalTcpList.length - 1 === h) {
                                                dates.sort((a, b) => new Date(a.date) - new Date(b.date))
                                                //const minDate = dates[0].date;
                                                const maxDate = dates.reverse()[0].date;
                                                const [fdate, ftime] = maxDate.split(' ');
                                                if (isValidDate(fdate, "YYYY/MM/DD")) {
                                                    const [y3, m3, d3] = fdate.split('/');
                                                    const date3 = `${d3}-${m3}-${y3}`;
                                                    const date4 = date3 + " " + ftime;
                                                    updateddatetime = date4;
                                                }
                                            }
                                        }

                                    }
                                }
                            }

                            const finaldata = {
                                "hospital_type": hospitalcode[k].hospitaltype_name,
                                "hospital_code": hospitalcode[k].hospitalcode_name,
                                "lastupdated": updateddatetime,
                                "grandtotalTcp": totalTcpList,
                                "dischargedTCP": dischargedTcpList,
                                "inhospitalTCP": inhospitalTcpList,
                                "activeTcp": activeTcpList,
                                "strapRemoveTcp": strapremoveTcpList,
                                "outGateTcp": outgateTcpList,
                            }

                            if (updateddatetime !== "-") {
                                finaltotalTcpList.push(finaldata);
                            }

                            if (hospitalcode.length === k + 1) {
                                settotalfinaltotalTcpList1(finaltotalTcpList);

                            }

                        }

                    } else if (res.data.success === false) {
                        alert("Empty Data");
                        setTotalTcp([]);
                        setActiveTcp([]);
                        setStrapRemoveTcp([]);
                        setOutGateTcp([]);
                        setsorteddatevalue("");

                  settotalfinaltotalTcpList1([]);

                        const finaltotalTcpList = [];

                        for (let k = 0; k < hospitalcode.length; k++) {

                            const totalTcpList = [];
                            const dischargedTcpList = [];
                            const inhospitalTcpList = [];
                            const activeTcpList = [];
                            const strapremoveTcpList = [];
                            const outgateTcpList = [];

                            let updateddatetime = "-";

                            const finaldata = {
                                "hospital_type": hospitalcode[k].hospitaltype_name,
                                "hospital_code": hospitalcode[k].hospitalcode_name,
                                "lastupdated": updateddatetime,
                                "grandtotalTcp": totalTcpList,
                                "dischargedTCP": dischargedTcpList,
                                "inhospitalTCP": inhospitalTcpList,
                                "activeTcp": activeTcpList,
                                "strapRemoveTcp": strapremoveTcpList,
                                "outGateTcp": outgateTcpList,
                            }

                            if (updateddatetime !== "-") {
                                finaltotalTcpList.push(finaldata);
                            }

                            if (hospitalcode.length === k + 1) {

                                settotalfinaltotalTcpList1(finaltotalTcpList);

                            }
                        }

                    }

                } else Promise.reject();
            }).catch((error) => {
                setTotalTcp([]);
                setActiveTcp([]);
                setStrapRemoveTcp([]);
                setOutGateTcp([]);
                setsorteddatevalue("");

                settotalfinaltotalTcpList1([]);


                alert("Something went wrong(" + error + ")");
            });
        }
    }



    useEffect(() => {
        const intervalCall = setInterval(() => {
            try {

                const formData = new FormData()
                formData.append('Hospital_type', masterdata.hospital_type);
                formData.append('Hospital_code', masterdata.hospital_code);

                Services.getHospitalsdataByHospitalwise(formData).then((res) => {

                    if (res.status === 200) {

                        if (res.data.success === true) {

                            const resdata = res.data.hospitaldata;

                            setTotalTcp([]);
                            setActiveTcp([]);
                            setStrapRemoveTcp([]);
                            setOutGateTcp([]);

                            const totalTcpList = [];
                            const activeTcpList = [];
                            const strapremoveTcpList = [];
                            const outgateTcpList = [];

                            settotalfinaltotalTcpList1([]);

                            for (let i = 0; i < resdata.length; i++) {

                                if (resdata[i].hospital_type === masterdata.hospital_type
                                    && resdata[i].hospital_code === masterdata.hospital_code
                                    && resdata[i].patient_status === "IN HOSPITAL") {

                                    totalTcpList.push(resdata[i]);

                                    if (resdata[i].tag_alertType_id === "33") {
                                        strapremoveTcpList.push(resdata[i]);
                                    } else if (resdata[i].tag_alertType_id === "50") {
                                        outgateTcpList.push(resdata[i]);
                                    } else {
                                        activeTcpList.push(resdata[i]);
                                    }

                                }
                                if (resdata.length === i + 1) {

                                    if (totalTcpList.length !== 0) {

                                        totalTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                        activeTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                        strapremoveTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                        outgateTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);


                                        setTotalTcp(totalTcpList);
                                        setActiveTcp(activeTcpList);
                                        setStrapRemoveTcp(strapremoveTcpList);
                                        setOutGateTcp(outgateTcpList);

                                        resdata.sort((firstItem, secondItem) => firstItem.tagId - secondItem.tagId);

                                        const dates = [];
                                        for (let h = 0; h < resdata.length; h++) {
                                            const [date, time] = resdata[h].tag_last_inserted_time.split(' ');
                                            if (isValidDate(date, "DD-MM-YYYY")) {
                                                const [d3, m3, y3] = date.split('-');
                                                const date1 = `${y3}/${m3}/${d3}`;
                                                const date2 = date1 + " " + time;
                                                const dit = {
                                                    date: date2,
                                                }
                                                dates.push(dit);
                                            }
                                            if (resdata.length - 1 === h) {
                                                dates.sort((a, b) => new Date(a.date) - new Date(b.date))
                                                //const minDate = dates[0].date;
                                                const maxDate = dates.reverse()[0].date;
                                                const [fdate, ftime] = maxDate.split(' ');
                                                if (isValidDate(fdate, "YYYY/MM/DD")) {
                                                    const [y3, m3, d3] = fdate.split('/');
                                                    const date3 = `${d3}-${m3}-${y3}`;
                                                    const date4 = date3 + " " + ftime;
                                                    setsorteddatevalue(date4);
                                                }
                                            }
                                        }


                                    } else {
                                        // alert("Empty Data");
                                        setTotalTcp([]);
                                        setActiveTcp([]);
                                        setStrapRemoveTcp([]);
                                        setOutGateTcp([]);
                                        setsorteddatevalue("");

                                    }
                                }
                            }


                            const finaltotalTcpList = [];

                            for (let k = 0; k < hospitalcode.length; k++) {
    
                                const totalTcpList = [];
                                const dischargedTcpList = [];
                                const inhospitalTcpList = [];
                                const activeTcpList = [];
                                const strapremoveTcpList = [];
                                const outgateTcpList = [];
    
                                let updateddatetime = "-";
    
                                for (let m = 0; m < resdata.length; m++) {
    
                                    if (resdata[m].hospital_type === hospitalcode[k].hospitaltype_name
                                        && resdata[m].hospital_code === hospitalcode[k].hospitalcode_name) {
                                        totalTcpList.push(resdata[m]);
    
                                        if (resdata[m].patient_status === "IN HOSPITAL") {
    
                                            inhospitalTcpList.push(resdata[m]);
                                            if (resdata[m].tag_alertType_id === "33") {
                                                strapremoveTcpList.push(resdata[m]);
                                            } else if (resdata[m].tag_alertType_id === "50") {
                                                outgateTcpList.push(resdata[m]);
                                            } else {
                                                activeTcpList.push(resdata[m]);
                                            }
    
                                        } else if (resdata[m].patient_status === "DISCHARGE") {
                                            dischargedTcpList.push(resdata[m]);
                                        }
    
                                    }
    
    
                                    if (resdata.length === m + 1) {
    
                                        if (totalTcpList.length !== 0) {
    
                                            totalTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                            dischargedTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                            inhospitalTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                            activeTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                            strapremoveTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                            outgateTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
    
                                            resdata.sort((firstItem, secondItem) => firstItem.tagId - secondItem.tagId);
                                            const dates = [];
                                            for (let h = 0; h < totalTcpList.length; h++) {
                                                const [date, time] = totalTcpList[h].tag_last_inserted_time.split(' ');
                                                if (isValidDate(date, "DD-MM-YYYY")) {
                                                    const [d3, m3, y3] = date.split('-');
                                                    const date1 = `${y3}/${m3}/${d3}`;
                                                    const date2 = date1 + " " + time;
                                                    const dit = {
                                                        date: date2,
                                                    }
                                                    dates.push(dit);
                                                }
                                                if (totalTcpList.length - 1 === h) {
                                                    dates.sort((a, b) => new Date(a.date) - new Date(b.date))
                                                    //const minDate = dates[0].date;
                                                    const maxDate = dates.reverse()[0].date;
                                                    const [fdate, ftime] = maxDate.split(' ');
                                                    if (isValidDate(fdate, "YYYY/MM/DD")) {
                                                        const [y3, m3, d3] = fdate.split('/');
                                                        const date3 = `${d3}-${m3}-${y3}`;
                                                        const date4 = date3 + " " + ftime;
                                                        updateddatetime = date4;
                                                    }
                                                }
                                            }
    
                                        }
                                    }
                                }
    
                                const finaldata = {
                                    "hospital_type": hospitalcode[k].hospitaltype_name,
                                    "hospital_code": hospitalcode[k].hospitalcode_name,
                                    "lastupdated": updateddatetime,
                                    "grandtotalTcp": totalTcpList,
                                    "dischargedTCP": dischargedTcpList,
                                    "inhospitalTCP": inhospitalTcpList,
                                    "activeTcp": activeTcpList,
                                    "strapRemoveTcp": strapremoveTcpList,
                                    "outGateTcp": outgateTcpList,
                                }
    
                                if (updateddatetime !== "-") {
                                    finaltotalTcpList.push(finaldata);
                                }
    
                                if (hospitalcode.length === k + 1) {
                                    settotalfinaltotalTcpList1(finaltotalTcpList);
    
                                }
    
                            }

                        } else if (res.data.success === false) {
                            //alert("Empty Data");
                            setTotalTcp([]);
                            setActiveTcp([]);
                            setStrapRemoveTcp([]);
                            setOutGateTcp([]);
                            setsorteddatevalue("");

                            settotalfinaltotalTcpList1([]);


                            const finaltotalTcpList = [];

                            for (let k = 0; k < hospitalcode.length; k++) {
    
                                const totalTcpList = [];
                                const dischargedTcpList = [];
                                const inhospitalTcpList = [];
                                const activeTcpList = [];
                                const strapremoveTcpList = [];
                                const outgateTcpList = [];
    
                                let updateddatetime = "-";
    
                                const finaldata = {
                                    "hospital_type": hospitalcode[k].hospitaltype_name,
                                    "hospital_code": hospitalcode[k].hospitalcode_name,
                                    "lastupdated": updateddatetime,
                                    "grandtotalTcp": totalTcpList,
                                    "dischargedTCP": dischargedTcpList,
                                    "inhospitalTCP": inhospitalTcpList,
                                    "activeTcp": activeTcpList,
                                    "strapRemoveTcp": strapremoveTcpList,
                                    "outGateTcp": outgateTcpList,
                                }
    
                                if (updateddatetime !== "-") {
                                    finaltotalTcpList.push(finaldata);
                                }
    
                                if (hospitalcode.length === k + 1) {
    
                                    settotalfinaltotalTcpList1(finaltotalTcpList);
    
                                }
                            }
    

                        }





                    } else Promise.reject();
                }).catch((error) => {
                    setTotalTcp([]);
                    setActiveTcp([]);
                    setStrapRemoveTcp([]);
                    setOutGateTcp([]);
                    setsorteddatevalue("");

                    settotalfinaltotalTcpList1([]);


                    // alert("Something went wrong(" + error + ")");
                });


            } catch (e) {
                console.log(e);
            }
        }, 2000);
        return () => {
            clearInterval(intervalCall);
        };
    }, [hospitalcode, masterdata.hospital_code, masterdata.hospital_type]);


    function isValidDate(dateString, format) {
        return moment(dateString, format, true).isValid();
    }

    const validateForm = (e) => {

        let formIsValid = true;

        if (!masterdata.hospital_type.trim()) {
            alert("Select Hospital Type");
            formIsValid = false;
        } else if (!masterdata.hospital_code.trim()) {
            alert("Select Hospital Code");
            formIsValid = false;
        }

        return formIsValid;
    }



    const Patient_with_tcp_Data = (patientTagData, alerttype, colorval) => (
        (() => {
            var sno = 0;
            return (
                <div className="center_center" >
                    <ImageList className="start_center" style={{ width: "100%", height: "100%", padding: "10px" }}>
                        {patientTagData.map((patientTagData, index) => (
                            (() => {

                                var alt1 = "";
                                var alt2 = "";
                                var alt3 = "";
                                var alt4 = "";

                                if (alerttype === "30") {
                                    alt1 = alerttype;
                                    alt2 = "55";
                                    alt3 = "31";
                                    alt4 = "";
                                } else {
                                    alt1 = alerttype;
                                    alt2 = alerttype;
                                    alt3 = alerttype;
                                    alt4 = alerttype;
                                }

                                let blink = "";
                                if (1.5 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                    if (2 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                        blink = "";
                                    } else {
                                        blink = "blink";
                                    }
                                } else if (1.5 >= parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                    blink = "blink";
                                }


                                sno = sno + 1;
                                if (patientTagData.tag_alertType_id === alt1 || patientTagData.tag_alertType_id === alt2
                                    || patientTagData.tag_alertType_id === alt3 || patientTagData.tag_alertType_id === alt4) {
                                    if (patientTagData.tag_tagtype_id === "01") {

                                        return (
                                            <div className="center_center" key={index} style={{ height: "100%", width: "12%", background: "white", margin: "2px" }}>
                                                <div className={blink} style={{ fontSize: "12px", fontWeight: "bold", flex: 1 }}>
                                                    <Popup trigger={
                                                        <label style={{ color: "black", flex: 1 }}>
                                                            {(() => {
                                                                return (
                                                                    <div style={{ flex: 1 }}>
                                                                        {(() => {
                                                                            return (
                                                                                <div style={{ flex: 1 }}>
                                                                                    {(() => {
                                                                                        if (colorval === "green") {
                                                                                            return (
                                                                                                <img src={parent_green} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                            )
                                                                                        } else if (colorval === "orange") {
                                                                                            return (
                                                                                                <img src={parent_orange} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                            )
                                                                                        } else if (colorval === "red") {
                                                                                            return (
                                                                                                <img src={parent_red} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <img src={parent_green} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                    <u style={{ flex: 1, cursor: "pointer", fontSize: "10px" }}>{/* T:{patient_tagid1} */} <br /> {patientTagData.tag_patient_name}
                                                                                        <br /> {patientTagData.tag_tagid}</u>
                                                                                    {(() => {

                                                                                        if (1.5 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {

                                                                                            if (2 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                                                                                return (
                                                                                                    <b style={{ flex: 1, fontSize: "8px", color: "green" }}> ( V : {patientTagData.tag_tagVoltage} ) </b>
                                                                                                )
                                                                                            } else {
                                                                                                return (
                                                                                                    <b style={{ flex: 1, fontSize: "8px", color: "orange" }}> ( V : {patientTagData.tag_tagVoltage} )</b>
                                                                                                )
                                                                                            }
                                                                                        } else if (1.5 >= parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                                                                            return (
                                                                                                <b style={{ flex: 1, fontSize: "8px", color: "red" }}> ( V : {patientTagData.tag_tagVoltage} )</b>
                                                                                            )
                                                                                        }
                                                                                    })()}
                                                                                    {/*                                           <br /><b style={{ flex: 1, fontSize: "8px", color: "green" }}>{patientTagData.tag_tagreadtime}</b>
 */}
                                                                                </div>
                                                                            )
                                                                        })()}
                                                                    </div>
                                                                )
                                                            })()}
                                                        </label>
                                                    }
                                                        style={{ color: "gray" }} position="top center">
                                                        <div style={{ color: "green" }}>
                                                            {/*<div className="center_center" style={{ color: "#190262", fontSize: "12px", fontWeight: "bold" }} >{patientTagData.tag_read_mastername}({patientTagData.tag_read_masterid}) </div>
                                      */} <p style={{ font: 'small-caption' }}>
                                                                {/* <b style={{ color: "black" }}>Sno : </b>{sno} */}
                                                                <b style={{ color: "red" }} >Tag Master : </b><b style={{ color: "#190262" }}>{patientTagData.tag_read_mastername} ({patientTagData.tag_read_masterid}) </b>
                                                                <br /><b style={{ color: "red" }} >Parent tag Id : </b>{patientTagData.tag_tagid}
                                                                <br /><b style={{ color: "red" }}>Child tag Id : </b>{patientTagData.tag_child_tagIds}
                                                                <br /><b style={{ color: "black" }} >Parent Name : </b>{patientTagData.tag_patient_name}
                                                                <br /><b style={{ color: "black" }} >Mobile : </b>{patientTagData.tag_patient_mobileno}
                                                                <br /><b style={{ color: "black" }} >Infrant count : </b>{patientTagData.tag_patient_infant_count}
                                                                <br /><b style={{ color: "black" }} >Address : </b>{patientTagData.tag_patient_address}
                                                                {/* <br /><b style={{ color: "black" }} >Readtime :  {patientTagData.tag_tagreadtime}</b> */}




                                                            </p>
                                                        </div>
                                                    </Popup>
                                                </div>
                                            </div>
                                        )
                                    }
                                    if (patientTagData.tag_tagtype_id === "00") {
                                        return (
                                            <div className="center_center" key={index} style={{ height: "100%", width: "12%", background: "white", margin: "2px" }}>
                                                <div className={blink} style={{ fontSize: "12px", fontWeight: "bold", flex: 1 }}>
                                                    <Popup trigger={
                                                        <label style={{ color: "black", flex: 1 }}>
                                                            {(() => {
                                                                return (
                                                                    <div style={{ flex: 1 }}>
                                                                        {(() => {
                                                                            if (colorval === "green") {
                                                                                if (patientTagData.tag_child_gender === "Girl") {
                                                                                    return (
                                                                                        <img src={babygirl_greenround2} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                    )
                                                                                } else if (patientTagData.tag_child_gender === "Boy") {
                                                                                    return (
                                                                                        <img src={babyboy_greenround2} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                    )
                                                                                }
                                                                            } else if (colorval === "orange") {
                                                                                if (patientTagData.tag_child_gender === "Girl") {
                                                                                    return (
                                                                                        <img src={babygirl_orangeround} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                    )
                                                                                } else if (patientTagData.tag_child_gender === "Boy") {
                                                                                    return (
                                                                                        <img src={babyboy_orangeround} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                    )
                                                                                }
                                                                            } else if (colorval === "red") {
                                                                                if (patientTagData.tag_child_gender === "Girl") {
                                                                                    return (
                                                                                        <img src={babygirl_redround} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                    )
                                                                                } else if (patientTagData.tag_child_gender === "Boy") {
                                                                                    return (
                                                                                        <img src={babyboy_redround} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                    )
                                                                                }
                                                                            } else {
                                                                                if (patientTagData.tag_child_gender === "Girl") {
                                                                                    return (
                                                                                        <img src={babygirl_greenround2} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                    )
                                                                                } else if (patientTagData.tag_child_gender === "Boy") {
                                                                                    return (
                                                                                        <img src={babyboy_greenround2} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                    )
                                                                                }
                                                                            }
                                                                        })()}
                                                                        <u style={{ flex: 1, cursor: "pointer", fontSize: "9px" }}>{/* T:{tag_tagid}  */}<br />{patientTagData.tag_patient_name} - {patientTagData.tag_child_gender}
                                                                            <br /> {patientTagData.tag_tagid}</u>
                                                                        {(() => {
                                                                            if (1.5 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                                                                if (2 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                                                                    return (
                                                                                        <b style={{ flex: 1, fontSize: "8px", color: "green" }}> ( V : {patientTagData.tag_tagVoltage}) </b>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <b style={{ flex: 1, fontSize: "8px", color: "orange" }}> ( V : {patientTagData.tag_tagVoltage})</b>
                                                                                    )
                                                                                }
                                                                            } else if (1.5 >= parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                                                                return (
                                                                                    <b style={{ flex: 1, fontSize: "8px", color: "red" }}> ( V : {patientTagData.tag_tagVoltage})</b>
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {/*                                     <br /><b style={{ flex: 1, fontSize: "8px", color: "green" }}>{patientTagData.tag_tagreadtime}</b>
 */}
                                                                    </div>
                                                                )
                                                            })()}
                                                        </label>
                                                    }
                                                        style={{ color: "gray" }} position="top center">
                                                        <div style={{ color: "green" }}>
                                                            {/* <div className="center_center" style={{ color: "#190262", fontSize: "12px", fontWeight: "bold" }} >{patientTagData.tag_read_mastername}({patientTagData.tag_read_masterid}) </div>
                                  */}
                                                            <p style={{ font: 'small-caption' }}>
                                                                {/* <b style={{ color: "black" }}>Sno : </b>{sno} */}
                                                                <b style={{ color: "red" }} >Tag Master : </b><b style={{ color: "#190262" }}>{patientTagData.tag_read_mastername} ({patientTagData.tag_read_masterid}) </b>
                                                                <br /><b style={{ color: "red" }}>Parent tag Id : </b>{patientTagData.patient_tagid}
                                                                <br /><b style={{ color: "red" }}>Child tag Id : </b>{patientTagData.tag_tagid}
                                                                <br /><b style={{ color: "black" }}>gender : </b>{patientTagData.tag_child_gender}
                                                                <br /><b style={{ color: "black" }} >Parent Name : </b>{patientTagData.tag_patient_name}
                                                                <br /><b style={{ color: "black" }} >Parent Mobile : </b>{patientTagData.tag_patient_mobileno}
                                                                <br /><b style={{ color: "black" }} >Parent Address : </b>{patientTagData.tag_patient_address}
                                                                {/* <br /><b style={{ color: "black" }} >Readtime : {patientTagData.tag_tagreadtime} </b> */}

                                                            </p>
                                                        </div>
                                                    </Popup>
                                                </div>
                                            </div>
                                        )
                                    }
                                }

                            })()
                        ))}

                    </ImageList>
                </div>
            )


        })()
    )

    const tagSearch_Data_view = (patientTagData) => (
        (() => {
            var sno = 0;
            return (
                <div className="center_center" >
                    <ImageList className="start_center" style={{ width: "100%", height: "100%", padding: "10px" }}>
                        {patientTagData.map((patientTagData, index) => (
                            (() => {

                                let alerttype = patientTagData.tag_alertType_id;
                                let colorval = "";

                                if (alerttype === "30" || alerttype === "55") {
                                    colorval = "green";
                                }

                                if (alerttype === "31") {
                                    colorval = "orange";
                                }

                                if (alerttype === "33" || alerttype === "50") {
                                    colorval = "red";
                                }

                                let blink = "";
                                if (1.5 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                    if (2 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                        blink = "";
                                    } else {
                                        blink = "blink";
                                    }
                                } else if (1.5 >= parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                    blink = "blink";
                                }

                                sno = sno + 1;
                                if (patientTagData.tag_tagtype_id === "01") {

                                    return (
                                        <div className="center_center" key={index} style={{ height: "100%", width: "12%", background: "white", margin: "2px" }}>
                                            <div className={blink} style={{ fontSize: "12px", fontWeight: "bold", flex: 1 }}>
                                                <Popup trigger={
                                                    <label style={{ color: "black", flex: 1 }}>
                                                        {(() => {
                                                            return (
                                                                <div style={{ flex: 1 }}>
                                                                    {(() => {
                                                                        return (
                                                                            <div style={{ flex: 1 }}>
                                                                                {(() => {
                                                                                    if (colorval === "green") {
                                                                                        return (
                                                                                            <img src={parent_green} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                        )
                                                                                    } else if (colorval === "orange") {
                                                                                        return (
                                                                                            <img src={parent_orange} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                        )
                                                                                    } else if (colorval === "red") {
                                                                                        return (
                                                                                            <img src={parent_red} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <img src={parent_green} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                        )
                                                                                    }
                                                                                })()}
                                                                                <u style={{ flex: 1, cursor: "pointer", fontSize: "10px" }}>{/* T:{patient_tagid1} */} <br /> {patientTagData.tag_patient_name}
                                                                                    <br /> {patientTagData.tag_tagid}</u>
                                                                                {(() => {

                                                                                    if (1.5 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {

                                                                                        if (2 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                                                                            return (
                                                                                                <b style={{ flex: 1, fontSize: "8px", color: "green" }}> ( V : {patientTagData.tag_tagVoltage} ) </b>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <b style={{ flex: 1, fontSize: "8px", color: "orange" }}> ( V : {patientTagData.tag_tagVoltage} )</b>
                                                                                            )
                                                                                        }
                                                                                    } else if (1.5 >= parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                                                                        return (
                                                                                            <b style={{ flex: 1, fontSize: "8px", color: "red" }}> ( V : {patientTagData.tag_tagVoltage} )</b>
                                                                                        )
                                                                                    }
                                                                                })()}
                                                                                {/*                                         <br /><b style={{ flex: 1, fontSize: "8px", color: "green" }}>{patientTagData.tag_tagreadtime}</b>
 */}
                                                                            </div>
                                                                        )
                                                                    })()}
                                                                </div>
                                                            )
                                                        })()}
                                                    </label>
                                                }
                                                    style={{ color: "gray" }} position="top center">
                                                    <div style={{ color: "green" }}>
                                                        {/*<div className="center_center" style={{ color: "#190262", fontSize: "12px", fontWeight: "bold" }} >{patientTagData.tag_read_mastername}({patientTagData.tag_read_masterid}) </div>
                                      */} <p style={{ font: 'small-caption' }}>
                                                            {/* <b style={{ color: "black" }}>Sno : </b>{sno} */}
                                                            <b style={{ color: "red" }} >Tag Master : </b><b style={{ color: "#190262" }}>{patientTagData.tag_read_mastername} ({patientTagData.tag_read_masterid}) </b>
                                                            <br /><b style={{ color: "red" }} >Parent tag Id : </b>{patientTagData.tag_tagid}
                                                            <br /><b style={{ color: "red" }}>Child tag Id : </b>{patientTagData.tag_child_tagIds}
                                                            <br /><b style={{ color: "black" }} >Parent Name : </b>{patientTagData.tag_patient_name}
                                                            <br /><b style={{ color: "black" }} >Mobile : </b>{patientTagData.tag_patient_mobileno}
                                                            <br /><b style={{ color: "black" }} >Infrant count : </b>{patientTagData.tag_patient_infant_count}
                                                            <br /><b style={{ color: "black" }} >Address : </b>{patientTagData.tag_patient_address}
                                                            {/* <br /><b style={{ color: "black" }} >Readtime :  {patientTagData.tag_tagreadtime}</b> */}




                                                        </p>
                                                    </div>
                                                </Popup>
                                            </div>
                                        </div>
                                    )
                                }
                                if (patientTagData.tag_tagtype_id === "00") {
                                    return (
                                        <div className="center_center" key={index} style={{ height: "100%", width: "12%", background: "white", margin: "2px" }}>
                                            <div className={blink} style={{ fontSize: "12px", fontWeight: "bold", flex: 1 }}>
                                                <Popup trigger={
                                                    <label style={{ color: "black", flex: 1 }}>
                                                        {(() => {
                                                            return (
                                                                <div style={{ flex: 1 }}>
                                                                    {(() => {
                                                                        if (colorval === "green") {
                                                                            if (patientTagData.tag_child_gender === "Girl") {
                                                                                return (
                                                                                    <img src={babygirl_greenround2} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                )
                                                                            } else if (patientTagData.tag_child_gender === "Boy") {
                                                                                return (
                                                                                    <img src={babyboy_greenround2} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                )
                                                                            }
                                                                        } else if (colorval === "orange") {
                                                                            if (patientTagData.tag_child_gender === "Girl") {
                                                                                return (
                                                                                    <img src={babygirl_orangeround} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                )
                                                                            } else if (patientTagData.tag_child_gender === "Boy") {
                                                                                return (
                                                                                    <img src={babyboy_orangeround} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                )
                                                                            }
                                                                        } else if (colorval === "red") {
                                                                            if (patientTagData.tag_child_gender === "Girl") {
                                                                                return (
                                                                                    <img src={babygirl_redround} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                )
                                                                            } else if (patientTagData.tag_child_gender === "Boy") {
                                                                                return (
                                                                                    <img src={babyboy_redround} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                )
                                                                            }
                                                                        } else {
                                                                            if (patientTagData.tag_child_gender === "Girl") {
                                                                                return (
                                                                                    <img src={babygirl_greenround2} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                )
                                                                            } else if (patientTagData.tag_child_gender === "Boy") {
                                                                                return (
                                                                                    <img src={babyboy_greenround2} style={{ height: "20px", width: "20px", cursor: "pointer" }} alt="parent_orange" />
                                                                                )
                                                                            }
                                                                        }
                                                                    })()}
                                                                    <u style={{ flex: 1, cursor: "pointer", fontSize: "9px" }}>{/* T:{tag_tagid}  */}<br />{patientTagData.tag_patient_name} - {patientTagData.tag_child_gender}
                                                                        <br /> {patientTagData.tag_tagid}</u>
                                                                    {(() => {
                                                                        if (1.5 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                                                            if (2 < parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                                                                return (
                                                                                    <b style={{ flex: 1, fontSize: "8px", color: "green" }}> ( V : {patientTagData.tag_tagVoltage}) </b>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <b style={{ flex: 1, fontSize: "8px", color: "orange" }}> ( V : {patientTagData.tag_tagVoltage})</b>
                                                                                )
                                                                            }
                                                                        } else if (1.5 >= parseFloat(patientTagData.tag_tagVoltage).toFixed(2)) {
                                                                            return (
                                                                                <b style={{ flex: 1, fontSize: "8px", color: "red" }}> ( V : {patientTagData.tag_tagVoltage})</b>
                                                                            )
                                                                        }
                                                                    })()}
                                                                    {/*                                   <br /><b style={{ flex: 1, fontSize: "8px", color: "green" }}>{patientTagData.tag_tagreadtime}</b>
 */}
                                                                </div>
                                                            )
                                                        })()}
                                                    </label>
                                                }
                                                    style={{ color: "gray" }} position="top center">
                                                    <div style={{ color: "green" }}>
                                                        {/* <div className="center_center" style={{ color: "#190262", fontSize: "12px", fontWeight: "bold" }} >{patientTagData.tag_read_mastername}({patientTagData.tag_read_masterid}) </div>
                                  */}
                                                        <p style={{ font: 'small-caption' }}>
                                                            {/* <b style={{ color: "black" }}>Sno : </b>{sno} */}
                                                            <b style={{ color: "red" }} >Tag Master : </b><b style={{ color: "#190262" }}>{patientTagData.tag_read_mastername} ({patientTagData.tag_read_masterid}) </b>
                                                            <br /><b style={{ color: "red" }}>Parent tag Id : </b>{patientTagData.patient_tagid}
                                                            <br /><b style={{ color: "red" }}>Child tag Id : </b>{patientTagData.tag_tagid}
                                                            <br /><b style={{ color: "black" }}>gender : </b>{patientTagData.tag_child_gender}
                                                            <br /><b style={{ color: "black" }} >Parent Name : </b>{patientTagData.tag_patient_name}
                                                            <br /><b style={{ color: "black" }} >Parent Mobile : </b>{patientTagData.tag_patient_mobileno}
                                                            <br /><b style={{ color: "black" }} >Parent Address : </b>{patientTagData.tag_patient_address}
                                                            {/* <br /><b style={{ color: "black" }} >Readtime : {patientTagData.tag_tagreadtime} </b> */}

                                                        </p>
                                                    </div>
                                                </Popup>
                                            </div>
                                        </div>
                                    )
                                }

                            })()
                        ))}

                    </ImageList>
                </div>
            )


        })()
    )

    return (
        <div className="full_frame_center">
            <div className="full_frame_center">
                <div className="start_center" style={{ width: "100%", marginLeft: "1%", marginRight: "1%", flexDirection: "row" }}>



                    <div className="end_center" style={{ width: "100%", fontSize: "12px", marginLeft: "1%", marginRight: "3%", fontWeight: "bold" }}>
                        Last data updated time : <b style={{ fontSize: "16px", color: "red", marginLeft: "5px" }}>{sorteddatevalue}</b> ,
                        Total Tags : <b style={{ fontSize: "25px", color: "#190262", marginLeft: "5px" }}>{totalTcp.length}</b> ,
                        Active : <b style={{ fontSize: "25px", color: "green", marginLeft: "5px" }}>{activeTcp.length}</b> ,
                        Strap Removed : <b style={{ fontSize: "25px", color: "red", marginLeft: "5px" }}>{strapRemoveTcp.length}</b>,
                        OUT Gates : <b style={{ fontSize: "25px", color: "red", marginLeft: "5px" }}>{outGateTcp.length}</b>
                    </div>

                </div>


                <div className="start_start" style={{ width: "100%" }}>

                    <div className="start_center" style={{ width: "50%", flexDirection: "row", marginLeft: "2%" }}>
                        <div className="start_start" style={{ width: "100%", flexDirection: "row" }}>

                            <div className="start_start" style={{ width: "100%", flexDirection: "column", marginBottom: "20px" }}>
                                <label htmlFor="masterName" className='text-controol' style={{ color: "#190262", fontSize: "12px", fontWeight: "bold" }}>
                                    Hospital Type<b style={{ color: "red" }}>*</b></label>
                                <Form.Select aria-label="Default select example" name="hospital_type" value={masterdata.hospital_type}
                                    onChange={(event) => handleChangeHospitaltypes(event, "hspttype")} style={{ fontSize: "12px", fontWeight: "bold", width: "70%" }} >
                                    <option value="">SELECT</option>
                                    {hospitaltype.map((hospitaltype, index) => (
                                        <option key={index} value={hospitaltype.hospitaltype_name}>{hospitaltype.hospitaltype_name}</option>
                                    ))}
                                </Form.Select>
                            </div>

                            <div className="start_start" style={{ width: "100%", flexDirection: "column", marginBottom: "20px" }}>
                                <label htmlFor="masterName" className='text-controol' style={{ color: "#190262", fontSize: "12px", fontWeight: "bold" }}>
                                    Hospital Code<b style={{ color: "red" }}>*</b></label>
                                <Form.Select aria-label="Default select example" name="hospital_code" value={masterdata.hospital_code}
                                    onChange={(event) => handleChangeHospitaltypes(event, "")} style={{ fontSize: "12px", fontWeight: "bold", width: "70%" }} >
                                    <option value="">SELECT</option>
                                    {hospitalcode.map((hospitalcode, index) => (
                                        <option key={index} value={hospitalcode.hospitalcode_name}>{hospitalcode.hospitalcode_name}</option>
                                    ))}
                                </Form.Select>
                            </div>

                            <div className="start_start" style={{ width: "100%", flexDirection: "column", marginBottom: "20px" }}>
                                <label htmlFor="masterName" className='text-controol' style={{ color: "#190262", fontSize: "12px", fontWeight: "bold" }}>
                                    Get</label>
                                <button className="btn btn-primary" style={{
                                    background: "#190262", borderColor: "#190262", color: "#ffffff",
                                    fontSize: "12px"
                                }} onClick={getData} >GET</button>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="start_start" style={{ width: "100%", padding: "10px", flexDirection: "column" }}>
                    <Table striped bordered hover style={{ fontSize: "12px" }}>
                        <thead style={{ background: "lightsteelblue", color: "black" }}>
                            <tr>
                                <th>S.no</th>
                                <th>Hospital</th>
                                <th>Data Last Updated</th>
                                <th>Grand Total Tags</th>
                                <th>Dicharged Tags</th>
                                <th>IN Hospital Tags</th>
                                <th>Active Tags</th>
                                <th>Strap Removed Tags</th>
                                <th>Out Gate Tags</th>
                            </tr>
                        </thead>
                        <tbody >
                            {totalfinaltotalTcpList1.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                    </Table>
                </div>


                <div className="start_center" style={{ background: "white", marginLeft: "1%", marginRight: "1%", flexDirection: "row", paddingTop: "10px", paddingBottom: "10px" }}>

                    <div className="start_center" style={{ width: "60%", background: "white", marginLeft: "1%", marginRight: "1%", flexDirection: "row" }}>

                        <div className="start_center" style={{ fontSize: "12px", fontWeight: "bold", marginLeft: "2%", flexDirection: "row" }}>
                            <img src={babyboy_greenround2} style={{ height: "20px", width: "20px", marginRight: "10px" }} alt="babyboy_greenround2" />
                            <img src={babygirl_greenround2} style={{ height: "20px", width: "20px", marginRight: "10px" }} alt="babygirl_greenround2" /> CHILD ACTIVE
                        </div>

                        <div className="start_center" style={{ fontSize: "12px", fontWeight: "bold", marginLeft: "2%", flexDirection: "row" }}>
                            <img src={babyboy_redround} style={{ height: "20px", width: "20px", marginRight: "10px" }} alt="babyboy_redround" />
                            <img src={babygirl_redround} style={{ height: "20px", width: "20px", marginRight: "10px" }} alt="babygirl_redround" /> CHILD STRAP REMOVED
                        </div>

                    </div>

                    <div className="start_center" style={{ width: "40%", background: "white", marginLeft: "1%", marginRight: "1%", flexDirection: "row" }}>

                        <div className="start_center" style={{ fontSize: "12px", fontWeight: "bold", marginLeft: "2%", flexDirection: "row" }}>
                            <img src={parent_green} style={{ height: "20px", width: "20px", marginRight: "10px" }} alt="babygirl_greenround2" /> PARENT ACTIVE
                        </div>

                    </div>

                </div>

                <div className="start_center" style={{ background: "pink", marginLeft: "1%", marginRight: "1%", flexDirection: "row", paddingTop: "1px", paddingBottom: "1px" }}>

                    <div className="search-header" style={{ background: "pink", padding: "10px" }}>
                        <div className="search-text">Search Name / Tagid:</div>
                        <input id="search-box" onChange={(event) => tagSearchedfunction(event)} />
                    </div>

                    <div style={{ height: "100%", width: "100%" }}>
                        {tagSearch_Data_view(tagSearchedData)}
                    </div>

                </div>

                <div className="start_center" style={{
                    background: "pink", fontSize: "16px", marginLeft: "1%",
                    paddingLeft: "1%", color: "#190262", marginRight: "1%"
                }}><b>IN/OUT GATE COUNT - {outGateTcp.length}</b></div>
                <ImageList style={{ background: "pink", marginLeft: "1%", marginRight: "1%", marginBottom: "1%" }}>

                    <div style={{ height: "100%", width: "100%" }}>
                        {Patient_with_tcp_Data(outGateTcp, "50", "red")}
                    </div>

                </ImageList>


                <div className="start_center" style={{
                    background: "pink", marginRight: "1%", fontSize: "16px", marginLeft: "1%",
                    paddingLeft: "1%", color: "#190262"
                }}><b>ACTIVE TAGS DATA {activeTcp.length}</b></div>
                <ImageList style={{ background: "pink", marginLeft: "1%", marginRight: "1%", marginBottom: "1%" }}>
                    <div style={{ height: "100%", width: "100%" }}>
                        {Patient_with_tcp_Data(activeTcp, "30", "green")}
                    </div>
                </ImageList>

                <div className="start_center" style={{
                    background: "pink", marginRight: "1%", fontSize: "16px", marginLeft: "1%",
                    paddingLeft: "1%", color: "#190262"
                }}><b>STRAP REMOVED TAGS DATA {strapRemoveTcp.length}</b></div>
                <ImageList style={{ background: "pink", marginLeft: "1%", marginRight: "1%", marginBottom: "3%" }}>
                    <div style={{ height: "100%", width: "100%" }}>
                        {Patient_with_tcp_Data(strapRemoveTcp, "33", "red")}
                    </div>
                </ImageList>

            </div>
            <div className="center_center" style={{ background: "#190262" }} >
                <div className="center_center" style={{ width: "80%", flexDirection: "column" }} >
                    <div>
                        <div className="App-link" href="http://maps.google.com/maps?q=loc:17.4630995,78.6093086(Location)" aria-label="Calon Address"
                            style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                            <b style={{ color: "white" }}>Address :</b> CAL-ON Instruments | SY.NO:193| PHASE 4,IDA CHERLAPALLY | HYDERABAD | 500051| INDIA
                        </div>
                    </div>
                    <div>
                        <div className="App-link" href="http://cal-on.com" aria-label="Calon Website"
                            style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                            <b style={{ color: "white" }}>Website :</b> http://cal-on.com
                        </div>
                    </div>
                </div>
                <div className="end_center" style={{ width: "20%" }} >
                    <div href="https://www.youtube.com/" aria-label="youtube"
                        className="youtube social" style={social_style} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faYoutube} size="1x" />
                    </div>
                    <div href="https://www.facebook.com/" aria-label="facebook"
                        className="facebook social" style={social_style} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faFacebook} size="1x" />
                    </div>
                    <div href="https://www.twitter.com" aria-label="twitter"
                        className="twitter social" style={social_style} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faTwitter} size="1x" />
                    </div>
                    <div href="https://www.instagram.com" aria-label="instagram"
                        className="instagram social" style={social_style} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="1x" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Dashboard;

