import axios from 'axios';

//to change port in two locations 1db.js 2services.js

// for local host
const API_BASE_URL = 'https://infant.calonev.com/';

class Services {

  //District_entry
  getHospitalsdata() { return axios.get(API_BASE_URL + 'get-hospitaldata.php'); }
  getHospitalsdataByHospitalwise(data) { return axios.post(API_BASE_URL + 'get-hospitaldata-byhospitalwise.php', data); }
  createHospitalsdata(data) { return axios.post(API_BASE_URL + 'insert-hospitaldata.php', data); }
  updateHospitalsdata(data) { return axios.post(API_BASE_URL + 'edit-hospitaldata.php', data); }
  deleteHospitalsdata(data) { return axios.delete(API_BASE_URL + 'delete-hospitaldata.php' + data); }
 
}

export default new Services()