import React, { useState, useEffect } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";//npm install --save @fortawesome/react-fontawesome @fortawesome/fontawesome-svg-core @fortawesome/free-brands-svg-icons
import { faYoutube, faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import Services from './Services'
import { Table } from "react-bootstrap";

const moment = require("moment");

const social_style = { background: "#edf5fe", color: "eb3223", padding: "5px", marginLeft: "5px", marginRight: "5px" }

// DashboardofAllHospitals Component
const DashboardofAllHospitals = () => {



    const [totalfinaltotalTcpList1, settotalfinaltotalTcpList1] = useState([]);
    const [totalfinaltotalTcpList2, settotalfinaltotalTcpList2] = useState([]);
    const [totalfinaltotalTcpList3, settotalfinaltotalTcpList3] = useState([]);
    const [totalfinaltotalTcpList4, settotalfinaltotalTcpList4] = useState([]);
    const [totalfinaltotalTcpList5, settotalfinaltotalTcpList5] = useState([]);
    const [totalfinaltotalTcpList6, settotalfinaltotalTcpList6] = useState([]);
    const [totalfinaltotalTcpList7, settotalfinaltotalTcpList7] = useState([]);
    const [totalfinaltotalTcpList8, settotalfinaltotalTcpList8] = useState([]);
    const [totalfinaltotalTcpList9, settotalfinaltotalTcpList9] = useState([]);
    const [totalfinaltotalTcpList10, settotalfinaltotalTcpList10] = useState([]);
    const [totalfinaltotalTcpList11, settotalfinaltotalTcpList11] = useState([]);
    const [totalfinaltotalTcpList12, settotalfinaltotalTcpList12] = useState([]);
    const [totalfinaltotalTcpList13, settotalfinaltotalTcpList13] = useState([]);
    const [totalfinaltotalTcpList14, settotalfinaltotalTcpList14] = useState([]);
    const [totalfinaltotalTcpList15, settotalfinaltotalTcpList15] = useState([]);
    const [totalfinaltotalTcpList16, settotalfinaltotalTcpList16] = useState([]);
    const [totalfinaltotalTcpList17, settotalfinaltotalTcpList17] = useState([]);
    const [totalfinaltotalTcpList18, settotalfinaltotalTcpList18] = useState([]);
    const [totalfinaltotalTcpList19, settotalfinaltotalTcpList19] = useState([]);
    const [totalfinaltotalTcpList20, settotalfinaltotalTcpList20] = useState([]);
    const [totalfinaltotalTcpList21, settotalfinaltotalTcpList21] = useState([]);
    const [totalfinaltotalTcpList22, settotalfinaltotalTcpList22] = useState([]);
    const [totalfinaltotalTcpList23, settotalfinaltotalTcpList23] = useState([]);


    const [hospitalcode, sethospitalcode] = useState([]);

    useEffect(() => {
        try {

            const hospitalcode = [
                {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GMH-TPT"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "MCH-VJA"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "RIMS-ONG"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "RIMS-SKLM"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "RIMS-KDP"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GVH-VSKP"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "KGH-VSKP"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GGH-GTR"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GGH-KKD"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GGH-ATP"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "MCHSPSR-NLR"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "GGH-KNL"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "DH-NDL"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "DH-MTM"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "DH-RJY"
                }, {
                    hospitaltype_name: "DME",
                    hospitalcode_name: "MCH-ELR"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "DH-HPUR"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "DH-TNL"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "DH-PDTR"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "DH-AKP"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "MCH-ONG"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "MCH-VZG"
                }, {
                    hospitaltype_name: "VVP",
                    hospitalcode_name: "MCH-ADONI"
                }
            ];

            sethospitalcode(hospitalcode);

        } catch (e) {
            console.log(e);
        }
    }, []);

   
    const getData = () => {
        settotalfinaltotalTcpList1([]);
        settotalfinaltotalTcpList2([]);
        settotalfinaltotalTcpList3([]);
        settotalfinaltotalTcpList4([]);
        settotalfinaltotalTcpList5([]);
        settotalfinaltotalTcpList6([]);
        settotalfinaltotalTcpList7([]);
        settotalfinaltotalTcpList8([]);
        settotalfinaltotalTcpList9([]);
        settotalfinaltotalTcpList10([]);
        settotalfinaltotalTcpList11([]);
        settotalfinaltotalTcpList12([]);
        settotalfinaltotalTcpList13([]);
        settotalfinaltotalTcpList14([]);
        settotalfinaltotalTcpList15([]);
        settotalfinaltotalTcpList16([]);
        settotalfinaltotalTcpList17([]);
        settotalfinaltotalTcpList18([]);
        settotalfinaltotalTcpList19([]);
        settotalfinaltotalTcpList20([]);
        settotalfinaltotalTcpList21([]);
        settotalfinaltotalTcpList22([]);
        settotalfinaltotalTcpList23([]);

        loaddata("DME", "GMH-TPT");
        loaddata("DME", "MCH-VJA");
        loaddata("DME", "RIMS-ONG");
        loaddata("DME", "RIMS-SKLM");
        loaddata("DME", "RIMS-KDP");
        loaddata("DME", "GVH-VSKP");
        loaddata("DME", "KGH-VSKP");
        loaddata("DME", "GGH-GTR");
        loaddata("DME", "GGH-KKD");
        loaddata("DME", "GGH-ATP");
        loaddata("DME", "MCHSPSR-NLR");
        loaddata("DME", "GGH-KNL");
        loaddata("DME", "DH-NDL");
        loaddata("DME", "DH-MTM");
        loaddata("DME", "DH-RJY");
        loaddata("DME", "MCH-ELR");

        loaddata("VVP", "DH-HPUR");
        loaddata("VVP", "DH-TNL");
        loaddata("VVP", "DH-PDTR");
        loaddata("VVP", "DH-AKP");
        loaddata("VVP", "MCH-ONG");
        loaddata("VVP", "MCH-VZG");
        loaddata("VVP", "MCH-ADONI");

    }



    function loaddata(hospitaltype_name, hospitalcode_name) {

        const formData = new FormData()
        formData.append('Hospital_type', hospitaltype_name);
        formData.append('Hospital_code', hospitalcode_name);

        Services.getHospitalsdataByHospitalwise(formData).then((res) => {

            if (res.status === 200) {
                if (res.data.success === true) {
                    const resdata = res.data.hospitaldata;

                    const finaltotalTcpList = [];

                    for (let k = 0; k < hospitalcode.length; k++) {

                        const totalTcpList = [];
                        const dischargedTcpList = [];
                        const inhospitalTcpList = [];
                        const activeTcpList = [];
                        const strapremoveTcpList = [];
                        const outgateTcpList = [];

                        let updateddatetime = "-";

                        for (let m = 0; m < resdata.length; m++) {

                            if (resdata[m].hospital_type === hospitalcode[k].hospitaltype_name
                                && resdata[m].hospital_code === hospitalcode[k].hospitalcode_name) {
                                totalTcpList.push(resdata[m]);

                                if (resdata[m].patient_status === "IN HOSPITAL") {

                                    inhospitalTcpList.push(resdata[m]);
                                    if (resdata[m].tag_alertType_id === "33") {
                                        strapremoveTcpList.push(resdata[m]);
                                    } else if (resdata[m].tag_alertType_id === "50") {
                                        outgateTcpList.push(resdata[m]);
                                    } else {
                                        activeTcpList.push(resdata[m]);
                                    }

                                } else if (resdata[m].patient_status === "DISCHARGE") {
                                    dischargedTcpList.push(resdata[m]);
                                }

                            }


                            if (resdata.length === m + 1) {


                                if (totalTcpList.length !== 0) {

                                    totalTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                    dischargedTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                    inhospitalTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                    activeTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                    strapremoveTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);
                                    outgateTcpList.sort((firstItem, secondItem) => firstItem.tag_tagid - secondItem.tag_tagid);

                                    resdata.sort((firstItem, secondItem) => firstItem.tagId - secondItem.tagId);
                                    const dates = [];
                                    for (let h = 0; h < totalTcpList.length; h++) {
                                        const [date, time] = totalTcpList[h].tag_last_inserted_time.split(' ');
                                        if (isValidDate(date, "DD-MM-YYYY")) {
                                            const [d3, m3, y3] = date.split('-');
                                            const date1 = `${y3}/${m3}/${d3}`;
                                            const date2 = date1 + " " + time;
                                            const dit = {
                                                date: date2,
                                            }
                                            dates.push(dit);
                                        }
                                        if (totalTcpList.length - 1 === h) {
                                            dates.sort((a, b) => new Date(a.date) - new Date(b.date))
                                            //const minDate = dates[0].date;
                                            const maxDate = dates.reverse()[0].date;
                                            const [fdate, ftime] = maxDate.split(' ');
                                            if (isValidDate(fdate, "YYYY/MM/DD")) {
                                                const [y3, m3, d3] = fdate.split('/');
                                                const date3 = `${d3}-${m3}-${y3}`;
                                                const date4 = date3 + " " + ftime;
                                                updateddatetime = date4;
                                            }
                                        }
                                    }

                                }

                            }
                        }

                        const finaldata = {
                            "hospital_type": hospitalcode[k].hospitaltype_name,
                            "hospital_code": hospitalcode[k].hospitalcode_name,
                            "lastupdated": updateddatetime,
                            "grandtotalTcp": totalTcpList,
                            "dischargedTCP": dischargedTcpList,
                            "inhospitalTCP": inhospitalTcpList,
                            "activeTcp": activeTcpList,
                            "strapRemoveTcp": strapremoveTcpList,
                            "outGateTcp": outgateTcpList,
                        }


                        if (updateddatetime !== "-") {
                            finaltotalTcpList.push(finaldata);
                        }

                        if (hospitalcode.length === k + 1) {

                            if (hospitaltype_name === "DME" && hospitalcode_name === "GMH-TPT") {
                                settotalfinaltotalTcpList1(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "MCH-VJA") {
                                settotalfinaltotalTcpList2(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "RIMS-ONG") {
                                settotalfinaltotalTcpList3(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "RIMS-SKLM") {
                                settotalfinaltotalTcpList4(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "RIMS-KDP") {
                                settotalfinaltotalTcpList5(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "GVH-VSKP") {
                                settotalfinaltotalTcpList6(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "KGH-VSKP") {
                                settotalfinaltotalTcpList7(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "GGH-GTR") {
                                settotalfinaltotalTcpList8(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "GGH-KKD") {
                                settotalfinaltotalTcpList9(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "GGH-ATP") {
                                settotalfinaltotalTcpList10(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "MCHSPSR-NLR") {
                                settotalfinaltotalTcpList11(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "GGH-KNL") {
                                settotalfinaltotalTcpList12(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "DH-NDL") {
                                settotalfinaltotalTcpList13(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "DH-MTM") {
                                settotalfinaltotalTcpList14(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "DH-RJY") {
                                settotalfinaltotalTcpList15(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "MCH-ELR") {
                                settotalfinaltotalTcpList16(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "DH-HPUR") {
                                settotalfinaltotalTcpList17(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "DH-TNL") {
                                settotalfinaltotalTcpList18(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "DH-PDTR") {
                                settotalfinaltotalTcpList19(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "DH-AKP") {
                                settotalfinaltotalTcpList20(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "MCH-ONG") {
                                settotalfinaltotalTcpList21(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "MCH-VZG") {
                                settotalfinaltotalTcpList22(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "MCH-ADONI") {
                                settotalfinaltotalTcpList23(finaltotalTcpList);
                            }
                        }

                    }

                } else if (res.data.success === false) {
                    const finaltotalTcpList = [];

                    for (let k = 0; k < hospitalcode.length; k++) {

                        const totalTcpList = [];
                        const dischargedTcpList = [];
                        const inhospitalTcpList = [];
                        const activeTcpList = [];
                        const strapremoveTcpList = [];
                        const outgateTcpList = [];

                        let updateddatetime = "-";

                        const finaldata = {
                            "hospital_type": hospitalcode[k].hospitaltype_name,
                            "hospital_code": hospitalcode[k].hospitalcode_name,
                            "lastupdated": updateddatetime,
                            "grandtotalTcp": totalTcpList,
                            "dischargedTCP": dischargedTcpList,
                            "inhospitalTCP": inhospitalTcpList,
                            "activeTcp": activeTcpList,
                            "strapRemoveTcp": strapremoveTcpList,
                            "outGateTcp": outgateTcpList,
                        }

                        if (updateddatetime !== "-") {
                            finaltotalTcpList.push(finaldata);
                        }

                        if (hospitalcode.length === k + 1) {

                            if (hospitaltype_name === "DME" && hospitalcode_name === "GMH-TPT") {
                                settotalfinaltotalTcpList1(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "MCH-VJA") {
                                settotalfinaltotalTcpList2(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "RIMS-ONG") {
                                settotalfinaltotalTcpList3(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "RIMS-SKLM") {
                                settotalfinaltotalTcpList4(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "RIMS-KDP") {
                                settotalfinaltotalTcpList5(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "GVH-VSKP") {
                                settotalfinaltotalTcpList6(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "KGH-VSKP") {
                                settotalfinaltotalTcpList7(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "GGH-GTR") {
                                settotalfinaltotalTcpList8(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "GGH-KKD") {
                                settotalfinaltotalTcpList9(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "GGH-ATP") {
                                settotalfinaltotalTcpList10(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "MCHSPSR-NLR") {
                                settotalfinaltotalTcpList11(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "GGH-KNL") {
                                settotalfinaltotalTcpList12(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "DH-NDL") {
                                settotalfinaltotalTcpList13(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "DH-MTM") {
                                settotalfinaltotalTcpList14(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "DH-RJY") {
                                settotalfinaltotalTcpList15(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "DME" && hospitalcode_name === "MCH-ELR") {
                                settotalfinaltotalTcpList16(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "DH-HPUR") {
                                settotalfinaltotalTcpList17(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "DH-TNL") {
                                settotalfinaltotalTcpList18(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "DH-PDTR") {
                                settotalfinaltotalTcpList19(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "DH-AKP") {
                                settotalfinaltotalTcpList20(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "MCH-ONG") {
                                settotalfinaltotalTcpList21(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "MCH-VZG") {
                                settotalfinaltotalTcpList22(finaltotalTcpList);
                            }
                            if (hospitaltype_name === "VVP" && hospitalcode_name === "MCH-ADONI") {
                                settotalfinaltotalTcpList23(finaltotalTcpList);
                            }

                        }
                    }

                }

            } else Promise.reject();
        }).catch((error) => {
            alert("Something went wrong(" + error + ")");
        });

    }


    function isValidDate(dateString, format) {
        return moment(dateString, format, true).isValid();
    }

    return (
        <div className="full_frame_center">
            <div className="full_frame_center">

                <div className="start_start" style={{ width: "100%", flexDirection: "column" }}>
                    <button className="btn btn-primary" style={{
                        background: "#190262",
                        borderColor: "#190262", color: "#ffffff", fontSize: "12px", marginLeft: "60px",marginTop:"10px"
                    }} onClick={getData} >GET OR REFRESH INFANT DATA</button>
                </div>

                <div className="start_start" style={{ width: "100%", padding: "10px", flexDirection: "column" }}>
                    <Table striped bordered hover style={{ fontSize: "12px" }}>
                        <thead style={{ background: "lightsteelblue", color: "black" }}>
                            <tr>
                                <th>S.no</th>
                                <th>Hospital</th>
                                <th>Data Last Updated</th>
                                <th>Grand Total Tags</th>
                                <th>Dicharged Tags</th>
                                <th>IN Hospital Tags</th>
                                <th>Active Tags</th>
                                <th>Strap Removed Tags</th>
                                <th>Out Gate Tags</th>
                            </tr>
                        </thead>

                        <tbody >
                            {totalfinaltotalTcpList1.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>1</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >

                            {totalfinaltotalTcpList2.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>2</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList3.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>3</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList4.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>4</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList5.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>5</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList6.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>6</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList7.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>7</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList8.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>8</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList9.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>9</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList10.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>10</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList11.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>11</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList12.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>12</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList13.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>13</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList14.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>14</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList15.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>15</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList16.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>16</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList17.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>17</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList18.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>18</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList19.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>19</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList20.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>20</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList21.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>21</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}
                        </tbody >
                        <tbody >
                            {totalfinaltotalTcpList22.map((mothertagdata, index) => (
                                <tr key={index}>
                                    <td>22</td>
                                    <th>{mothertagdata.hospital_type} {mothertagdata.hospital_code}</th>
                                    <td>{mothertagdata.lastupdated}</td>
                                    <td>{mothertagdata.grandtotalTcp.length}</td>
                                    <td>{mothertagdata.dischargedTCP.length}</td>
                                    <td>{mothertagdata.inhospitalTCP.length}</td>
                                    <td>{mothertagdata.activeTcp.length}</td>
                                    <td>{mothertagdata.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata.outGateTcp.length}</td>
                                </tr>
                            ))}

                            {totalfinaltotalTcpList23.map((mothertagdata23, index) => (
                                <tr key={index}>
                                    <td>23</td>
                                    <th>{mothertagdata23.hospital_type} {mothertagdata23.hospital_code}</th>
                                    <td>{mothertagdata23.lastupdated}</td>
                                    <td>{mothertagdata23.grandtotalTcp.length}</td>
                                    <td>{mothertagdata23.dischargedTCP.length}</td>
                                    <td>{mothertagdata23.inhospitalTCP.length}</td>
                                    <td>{mothertagdata23.activeTcp.length}</td>
                                    <td>{mothertagdata23.strapRemoveTcp.length}</td>
                                    <td>{mothertagdata23.outGateTcp.length}</td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>

                </div>








            </div>
            <div className="center_center" style={{ background: "#190262" }} >
                <div className="center_center" style={{ width: "80%", flexDirection: "column" }} >
                    <div>
                        <div className="App-link" href="http://maps.google.com/maps?q=loc:17.4630995,78.6093086(Location)" aria-label="Calon Address"
                            style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                            <b style={{ color: "white" }}>Address :</b> CAL-ON Instruments | SY.NO:193| PHASE 4,IDA CHERLAPALLY | HYDERABAD | 500051| INDIA
                        </div>
                    </div>
                    <div>
                        <div className="App-link" href="http://cal-on.com" aria-label="Calon Website"
                            style={{ color: "white" }} target="_blank" rel="noopener noreferrer">
                            <b style={{ color: "white" }}>Website :</b> http://cal-on.com
                        </div>
                    </div>
                </div>
                <div className="end_center" style={{ width: "20%" }} >
                    <div href="https://www.youtube.com/" aria-label="youtube"
                        className="youtube social" style={social_style} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faYoutube} size="1x" />
                    </div>
                    <div href="https://www.facebook.com/" aria-label="facebook"
                        className="facebook social" style={social_style} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faFacebook} size="1x" />
                    </div>
                    <div href="https://www.twitter.com" aria-label="twitter"
                        className="twitter social" style={social_style} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faTwitter} size="1x" />
                    </div>
                    <div href="https://www.instagram.com" aria-label="instagram"
                        className="instagram social" style={social_style} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="1x" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DashboardofAllHospitals;

