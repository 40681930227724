import React, { useState, useEffect } from "react";
import "../../App.css";
import { useNavigate, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Services from '../Services'
import swal from 'sweetalert';

import Sky from 'react-sky';

// you can pass imported images to Sky
//import myImage1 from "../../infrant_gray.png"
//import myImage2 from "../../infrant_green.png"
//import myImage3 from "../../infrant_orange.png"
//import myImage4 from "../../infrant_pic.jpg"
//import myImage5 from "../../infrant_red.png"
//import myImage6 from "../../infrant.png"
import myImage7 from "../../infant2.gif"
//import myImage8 from "../../infant.gif"

//const moment = require("moment");

const UserLogin = () => {
    const navigate = useNavigate();

    const [login, setLogin] = useState({
        login_user_nm: "",
        login_pwd_tx: ""
    });

    useEffect(() => {

        //alert(localStorage.getItem('login_newstaff_id'));


        if (localStorage.getItem('login_newstaff_id') !== null) {
            setLogin({
                login_user_nm: localStorage.getItem('login_newstaff_id'),
                login_pwd_tx: localStorage.getItem('login_newstaff_id')
            });
            //alert(localStorage.getItem('login_newstaff_id'));

        }

        var data = {
            staff_display_nm: "Super Admin",
            staff_role: "Super Admin",
            staff_department: "",
            staff_mobile_nu: "",
            staff_photo: "",
            staff_aadharno: "",
            staff_current_address: "",
            staff_perminent_address: "",
            work_place: "",
            hospital_nm: "",
            hospital_type: "",
            hospital_code: "",
            district_nm: "",
            state_nm: "",
            login_user_nm: "SuperAdmin",
            login_pwd_tx: "SuperAdmin@123"
        };
        //Services.deleteAdmin("1");
        Services.createadmin(data);
    }, []);

    useEffect(() => {
        var data = {
            staff_display_nm: "State Admin",
            staff_role: "State Admin",
            staff_department: "",
            staff_mobile_nu: "",
            staff_photo: "",
            staff_aadharno: "",
            staff_current_address: "",
            staff_perminent_address: "",
            work_place: "",
            hospital_nm: "",
            hospital_type: "",
            hospital_code: "",
            district_nm: "",
            state_nm: "",
            login_user_nm: "StateAdmin",
            login_pwd_tx: "StateAdmin@123"
        };
        //Services.deleteAdmin("1");
        Services.createadmin(data);
    }, []);

    const [validation, setValidation] = useState({
        login_user_nm: "",
        login_pwd_tx: ""
    });

    function handleChange(event) {
        const { name, value } = event.target;
        setLogin({ ...login, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {

            const data = {
                login_user_nm: login.login_user_nm,
                login_pwd_tx: login.login_pwd_tx
            };

            Services.loginStaff(data).then(res => {

                if (res.status === 200) {

                    if (res.data.status === false) {
                        swal({
                            text: res.data.message,
                            icon: "error"
                        });

                        navigate("/userLogin");

                    } else if (res.data.status === true) {

                        swal({
                            text: res.data.message,
                            icon: "success"
                        });

                        //let _id = res.data.data.userDtls._id;
                        // let key = res.data.data.userDtls.key;
                        let staff_nu = res.data.data.userDtls.staff_nu;
                        let staff_id = res.data.data.userDtls.staff_id;
                        let staff_status = res.data.data.userDtls.staff_status;
                        let staff_display_nm = res.data.data.userDtls.staff_display_nm;
                        let staff_role = res.data.data.userDtls.staff_role;
                        let staff_department = res.data.data.userDtls.staff_department;
                        let staff_mobile_nu = res.data.data.userDtls.staff_mobile_nu;
                        //let staff_photo = res.data.data.userDtls.staff_photo;
                        let staff_aadharno = res.data.data.userDtls.staff_aadharno;
                        let staff_current_address = res.data.data.userDtls.staff_current_address;
                        let staff_perminent_address = res.data.data.userDtls.staff_perminent_address;
                        let work_place = res.data.data.userDtls.work_place;
                        let hospital_nm = res.data.data.userDtls.hospital_nm;
                        let hospital_type = res.data.data.userDtls.hospital_type;
                        let hospital_code = res.data.data.userDtls.hospital_code;
                        let district_nm = res.data.data.userDtls.district_nm;
                        let state_nm = res.data.data.userDtls.state_nm;
                        let login_user_nm = res.data.data.userDtls.login_user_nm;
                        let login_pwd_tx = res.data.data.userDtls.login_pwd_tx;
                        let last_login_ts = res.data.data.userDtls.last_login_ts;
                        //let record_created_date = res.data.data.userDtls.record_created_date;
                        //let i_ts = res.data.data.userDtls.i_ts;

                        localStorage.clear();

                        sessionStorage.setItem('login_staff_nu', staff_nu);
                        sessionStorage.setItem('login_staff_id', staff_id);
                        sessionStorage.setItem('login_staff_status', staff_status);
                        sessionStorage.setItem('login_staff_display_nm', staff_display_nm);
                        sessionStorage.setItem('login_staff_role', staff_role);
                        sessionStorage.setItem('login_staff_department', staff_department);
                        sessionStorage.setItem('login_staff_mobile_nu', staff_mobile_nu);
                        sessionStorage.setItem('login_staff_aadharno', staff_aadharno);
                        sessionStorage.setItem('login_staff_current_address', staff_current_address);
                        sessionStorage.setItem('login_staff_perminent_address', staff_perminent_address);
                        sessionStorage.setItem('login_work_place', work_place);
                        sessionStorage.setItem('login_hospital_nm', hospital_nm);
                        sessionStorage.setItem('login_hospital_type', hospital_type);
                        sessionStorage.setItem('login_hospital_code', hospital_code);
                        sessionStorage.setItem('login_district_nm', district_nm);
                        sessionStorage.setItem('login_state_nm', state_nm);
                        sessionStorage.setItem('login_login_user_nm', login_user_nm);
                        sessionStorage.setItem('login_login_pwd_tx', login_pwd_tx);
                        sessionStorage.setItem('login_last_login_ts', last_login_ts);

                        navigate("/homepage");

                    }
                } else {
                    Promise.reject()
                }
            }).catch(err => {
                swal({
                    text: "Something went wrong!",
                    icon: "error"
                });

                navigate("/userLogin");

            });
        }
    };

    const validateForm = (e) => {
        let formIsValid = true;
        let errors = { ...validation }

        if (!login.login_user_nm?.trim()) {
            errors.login_user_nm = "*Please enter your login_user_nm.";
            formIsValid = false;
        } else {
            errors.login_user_nm = "";
        }

        if (!login.login_pwd_tx?.trim()) {
            errors.login_pwd_tx = "*Please enter your login_pwd_tx.";
            formIsValid = false;
        } else {
            errors.login_pwd_tx = "";
        }

        setValidation(errors);
        return formIsValid;
    };

    return (
        <div className="full_frame_center" >
            <div className="full_frame_center_center" style={{ flexDirection: "column"}}>
                <Sky images={{ 0: myImage7/* 5: your other image... */ }}
                    //how={10} /* Pass the number of images Sky will render chosing randomly */
                    //time={50} /* time of animation */
                    //size={'100%'} /* size of the rendered images */
                    background={'pink'} /* color of background */ />
                <div className="center_center" style={{ flexDirection: "row" }}>
                    <h2 style={{ color: "blue" }} >INFANT PROTECTION SYSTEM</h2>
                </div>
                <div className="center_center" style={{ background: "white",'borderRadius': '5px',
                 marginLeft: "10%", marginRight: "10%", padding: "5%" }}>

                    <img src={myImage7} style={{ margin: "1%"}} alt="myImage7" />      

                    <div className="start_start" name="form" style={{ flexDirection: "column", margin: "10%" }}>
                        <p className="center_center" style={{ width: "100%", color: "blue" }}>Sign in to start your session</p>

                        <div>
                            <div style={{ marginBottom: "10px", fontWeight: 'bold', fontSize: "12px" }}>
                                <input type="text" className="form-control" name="login_user_nm" value={login.login_user_nm}
                                    onChange={(e) => handleChange(e)} style={{ width: "300px", fontSize: "14px" }} placeholder="User name" />
                                <div className="errorMsg" onChange={(e) => handleChange(e)} style={{ color: "blue" }}>{validation.login_user_nm}</div>
                            </div>
                            <div style={{ marginBottom: "10px", fontWeight: 'bold', fontSize: "12px" }}>
                                <input type="login_pwd_tx" className="form-control" name="login_pwd_tx" value={login.login_pwd_tx}
                                    onChange={(e) => handleChange(e)} style={{ width: "300px", fontSize: "14px" }} placeholder="Password" />
                                <div className="errorMsg" onChange={(e) => handleChange(e)} style={{ color: "blue" }}>{validation.login_pwd_tx}</div>
                            </div>
                            <div className="start_start" style={{ marginTop: "30px", marginBottom: "30px" }}>
                                <div className="center_center" style={{ width: "100%" }}>
                                    <button type="button" className="btn btn-primary" style={{ background: "red", borderColor: "red", color: "white" }} 
                                    onClick={handleSubmit}>Sign in</button>
                                </div>
                            </div>
                        </div>
                        <p className="center_center" style={{ width: "100%" }}>
                            <NavLink to="/resetpassword" >I forgot my login password</NavLink>
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserLogin;