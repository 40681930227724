import React from "react";
import "./App.css";
import { HashRouter as Router, Routes, Route  } from 'react-router-dom';//npm install react-router-dom --save

import "bootstrap/dist/css/bootstrap.css";//npm install --save react-bootstrap bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import HomePage from './Components/HomePage';
import UserLogin  from './Components/Login/UserLogin.js';

function App() {
  return (
    <Router>
      <div className="full_frame_center">
        <div >
          <Routes >
            <Route exact path="/*" element={<HomePage/>} />
            <Route path="/userLogin" element={<UserLogin/>} />
            <Route path="/homepage/*" element={<HomePage/>} />
          </Routes >
        </div>
      </div>
    </Router>
  );
}

export default App;

